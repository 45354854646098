import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'


const routes = [{
		path: "",
		component: () => import("@/views/Main.vue"),
		redirect: "/index",
		children: [{
				path: "/index",
				name: "首页",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/Index.vue"),
			},

			{
				path: '/addannouncement',
				name: 'addannouncement',
				component: () => import('@/views/System/Sysset/AddAnnouncement.vue'),
				meta: {
					title: '新增公告',
				}
			},

			{
				path: '/noticelist',
				name: 'addAnnouncement',
				component: () => import('@/views/System/Sysset/NoticeList.vue'),
				meta: {
					title: '公告列表',
				}
			},
			{
				path: '/domainsettings',
				name: 'domainsettings',
				component: () => import('@/views/System/Sysset/DomainSettings.vue'),
				meta: {
					title: '域名设置',
				}
			},
			{
				path: '/blacklist',
				name: 'blacklist',
				component: () => import('@/views/System/Sysset/BlackList.vue'),
				meta: {
					title: '黑名单IP',
				}
			},
			{
				path: '/swiper',
				name: 'swiper',
				component: () => import('@/views/System/AdsImg/Swiper.vue'),
				meta: {
					title: 'APP广告',
				}
			},
			{
				path: '/swiper0',
				name: 'swiper0',
				component: () => import('@/views/System/AdsImg/Swiper0.vue'),
				meta: {
					title: '首页轮播图广告',
				}
			},


			{
				path: '/swiper1',
				name: 'swiper1',
				component: () => import('@/views/System/AdsImg/Swiper1.vue'),
				meta: {
					title: '首页弹窗广告',
				}
			},
			{
				path: '/swiper2',
				name: 'swiper2',
				component: () => import('@/views/System/AdsImg/Swiper2.vue'),
				meta: {
					title: '漫画轮播图广告',
				}
			},
			{
				path: '/swiper3',
				name: 'swiper3',
				component: () => import('@/views/System/AdsImg/Swiper3.vue'),
				meta: {
					title: '社区轮播图广告',
				}
			},
			{
				path: '/swiper4',
				name: 'swiper4',
				component: () => import('@/views/System/AdsImg/Swiper4.vue'),
				meta: {
					title: '短视频热点广告',
				}
			},
			{
				path: '/swiper5',
				name: 'swiper5',
				component: () => import('@/views/System/AdsImg/Swiper5.vue'),
				meta: {
					title: '长视频内容广告',
				}
			},

			{
				path: '/swiper6',
				name: 'swiper6',
				component: () => import('@/views/System/AdsImg/Swiper6.vue'),
				meta: {
					title: '社区内容广告',
				}
			},
			{
				path: '/swiper7',
				name: 'swiper7',
				component: () => import('@/views/System/AdsImg/Swiper7.vue'),
				meta: {
					title: '长视频详情页广告',
				}
			},
			{
				path: '/swiper8',
				name: 'swiper8',
				component: () => import('@/views/System/AdsImg/Swiper8.vue'),
				meta: {
					title: '首页底部广告',
				}
			},
			{
				path: '/swiper9',
				name: 'swiper9',
				component: () => import('@/views/System/AdsImg/Swiper9.vue'),
				meta: {
					title: '漫画详情页广告',
				}
			},
			{
				path: '/swiper10',
				name: 'swiper10',
				component: () => import('@/views/System/AdsImg/Swiper10.vue'),
				meta: {
					title: '应用中心轮播图广告',
				}
			},
			{
				path: '/swiper11',
				name: 'swiper11',
				component: () => import('@/views/System/AdsImg/Swiper11.vue'),
				meta: {
					title: 'app启动图',
				}
			},
			
			{
				path: '/swiper12',
				name: 'swiper12',
				component: () => import('@/views/System/AdsImg/Swiper12.vue'),
				meta: {
					title: '首页菜单广告图',
				}
			},
			{
				path: '/swiper13',
				name: 'swiper13',
				component: () => import('@/views/System/AdsImg/Swiper13.vue'),
				meta: {
					title: '免费菜单广告图',
				}
			},
			{
				path: '/swiper14',
				name: 'swiper14',
				component: () => import('@/views/System/AdsImg/Swiper14.vue'),
				meta: {
					title: '金币菜单广告图',
				}
			},
			{
				path: '/swiper15',
				name: 'swiper15',
				component: () => import('@/views/System/AdsImg/Swiper15.vue'),
				meta: {
					title: '落地页静态图片',
				}
			},
			{
				path: '/statistics',
				name: 'statistics',
				component: () => import('@/views/System/AdsImg/statistics.vue'),
				meta: {
					title: '广告统计',
				}
			},

			{
				path: '/rechargelist',
				name: 'rechargelist',
				component: () => import('@/views/System/Recharge/RechargeList.vue'),
				meta: {
					title: '充值设置',
				}
			},
			{
				path: '/payconfig',
				name: 'payconfig',
				component: () => import('@/views/System/Recharge/PayConfig.vue'),
				meta: {
					title: '通道配置',
				}
			},
			{
				path: '/glod',
				name: 'glod',
				component: () => import('@/views/System/Recharge/Glod.vue'),
				meta: {
					title: '金币充值',
				}
			},
			{
				path: '/member',
				name: 'member',
				component: () => import('@/views/System/Recharge/Member.vue'),
				meta: {
					title: '会员充值',
				}
			},

			{
				path: '/htlist',
				name: 'htlist',
				component: () => import('@/views/Community/TopicList/HtList.vue'),
				meta: {
					title: '话题列表',
					keepAlive: true
				}
			},
			{
				path: '/guanfangnews',
				name: 'guanfangnews',
				component: () => import('@/views/Community/FaTie/guanfangnews.vue'),
				meta: {
					title: '官方帖子列表',
					keepAlive: true
				}
			},
			
			{
				path: '/gfgonggao',
				name: 'gfgonggao',
				component: () => import('@/views/Community/FaTie/gfgonggao.vue'),
				meta: {
					title: '官方公告',
					keepAlive: true
				}
			},
			
			{
				path: '/fatielist',
				name: 'fatielist',
				component: () => import('@/views/Community/FaTie/FatieList.vue'),
				meta: {
					title: '发贴审核列表',
					keepAlive: true
				}
			},
			{
				path: '/shortaddvideo',
				name: 'shortaddvideo',
				component: () => import('@/views/Natural/Short/ShortAddVideo.vue'),
				meta: {
					title: '新增视频',
				}
			},
			{
				path: '/examinevideo',
				name: 'examinevideo',
				component: () => import('@/views/Natural/Short/ExamineVideo.vue'),
				meta: {
					title: '视频审核',
				}
			},

			{
				path: '/shortvideo',
				name: 'shortvideo',
				component: () => import('@/views/Natural/Short/ShortVideo.vue'),
				meta: {
					title: '短视频列表',
					keepAlive: true
				}
			},

			{
				path: '/label',
				name: 'label',
				component: () => import('@/views/Natural/Short/ShortLabel.vue'),
				meta: {
					title: '视频标签',
				}
			},
			{
				path: '/addvideo',
				name: 'addvideo',
				component: () => import('@/views/Natural/Long/AddVideo.vue'),
				meta: {
					title: '新增视频',
				}
			},
			{
				path: '/longexaminevideo',
				name: 'longexaminevideo',
				component: () => import('@/views/Natural/Long/LongExamineVideo.vue'),
				meta: {
					title: '视频审核',
				}
			},
			{
				path: '/longvideo',
				name: 'longvideo',
				component: () => import('@/views/Natural/Long/LongVideo.vue'),
				meta: {
					title: '视频列表',
					keepAlive: true
				}
			},
			{
				path: '/bigpagevideo',
				name: 'bigpagevideo',
				component: () => import('@/views/Natural/Long/BigPageVideo.vue'),
				meta: {
					title: '视频列表',
					keepAlive: true
				}
			},
			{
				path: '/longcategory',
				name: 'longcategory',
				component: () => import('@/views/Natural/Long/LongCategory.vue'),
				meta: {
					title: '视频分类',
				}
			},
			{
				path: '/longlabel',
				name: 'longlabel',
				component: () => import('@/views/Natural/Long/LongLabel.vue'),
				meta: {
					title: '视频标签',
					keepAlive: true
				}
			},
			
			{
				path: '/biglonglabel',
				name: 'biglonglabel',
				component: () => import('@/views/Natural/Long/BigLongLabel.vue'),
				meta: {
					title: '视频标签分类',
					keepAlive: true
				}
			},
			

			{
				path: '/staractress',
				name: 'staractress',
				component: () => import('@/views/Natural/Long/StarActress.vue'),
				meta: {
					title: '明星女优',
					keepAlive: true
				}
			},
			{
				path: '/institution',
				name: 'institution',
				component: () => import('@/views/Natural/Long/Institution.vue'),
				meta: {
					title: '机构传媒',
					keepAlive: true
				}
			},

			{
				path: '/orderList',
				name: 'orderList',
				component: () => import('@/views/Order/OrderList.vue'),
				meta: {
					title: '会员订单列表',
				}
			},

			{
				path: '/orderListbudan',
				name: 'orderListbudan',
				component: () => import('@/views/Order/OrderListbudan.vue'),
				meta: {
					title: '会员订单充值补单列表',
				}
			},

			{
				path: '/orderList2',
				name: 'orderList2',
				component: () => import('@/views/Order/OrderList2.vue'),
				meta: {
					title: '金币订单列表',
				}
			},

			{
				path: '/orderlist2budan',
				name: 'orderlist2budan',
				component: () => import('@/views/Order/OrderList2budan.vue'),
				meta: {
					title: '金币订单充值补单列表',
				}
			},



			{
				path: '/withdrawalreview',
				name: 'withdrawalreview',
				component: () => import('@/views/Order/WithdrawalReview.vue'),
				meta: {
					title: '提现订单审核',
				}
			},
			{
				path: '/withlist',
				name: 'withlist',
				component: () => import('@/views/Withdrawal/WithList.vue'),
				meta: {
					title: '提现列表',
				},
			},
			{
				path: '/withdrawalrate',
				name: 'withdrawalrate',
				component: () => import('@/views/Withdrawal/WithdrawalRate.vue'),
				meta: {
					title: '提先费率设置',
				},
			},
			{
				path: '/comiccategory',
				name: 'comiccategory',
				component: () => import('@/views/Comic/ComicCategory.vue'),
				meta: {
					title: '漫画分类',
				},
			},
			{
				path: '/comicList',
				name: 'comicList',
				component: () => import('@/views/Comic/ComicList.vue'),
				meta: {
					title: '漫画列表',
					keepAlive: true
				},
			},
			{
				path: '/comicdiversity',
				name: 'comicdiversity',
				component: () => import('@/views/Comic/ComicDiversity.vue'),
				meta: {
					title: '漫画分集',
				},
			},
			{
				path: '/comiclabel',
				name: 'comiclabel',
				component: () => import('@/views/Comic/ComicLabel.vue'),
				meta: {
					title: '漫画标签',
				},
			},

			{
				path: '/shortrecordslist',
				name: 'shortrecordslist',
				component: () => import('@/views/Consumption/ShortRecordsList.vue'),
				meta: {
					title: '短视频消费记录',
				},
			},
			{
				path: '/longrecordslist',
				name: 'longrecordslist',
				component: () => import('@/views/Consumption/LongRecordsList.vue'),
				meta: {
					title: '长视频消费记录',
				},
			},
			{
				path: '/memberrecordslist',
				name: 'memberrecordslist',
				component: () => import('@/views/Consumption/MemberRecordsList.vue'),
				meta: {
					title: '会员消费记录',
				},
			},
			{
				path: '/agentset',
				name: 'agentset',
				component: () => import('@/views/Agent/AgentSet.vue'),
				meta: {
					title: '分层设置',
				},
			},
			{
				path: '/agentadmincpa',
				name: 'agentadmincpa',
				component: () => import('@/views/Agent/AgentAdmincpa.vue'),
				meta: {
					title: 'CPA',
				},
			},
			{
				path: '/totalcpa',
				name: 'totalcpa',
				component: () => import('@/views/Agent/TotalCpa.vue'),
				meta: {
					title: '总PCA数据',
				},
			},
			{
				path: '/cpasettlist',
				name: 'cpasettlist',
				component: () => import('@/views/Agent/cpasettlist.vue'),
				meta: {
					title: 'cpa结算列表',
				},
			},
			{
				path: '/cpssettlist',
				name: 'cpssettlist',
				component: () => import('@/views/Agent/cpssettlist.vue'),
				meta: {
					title: 'cps结算列表',
				},
			},
			{
				path: '/agentadmincps',
				name: 'agentadmincps',
				component: () => import('@/views/Agent/AgentAdmincps.vue'),
				meta: {
					title: 'CPS	',
				},
			},
			{
				path: '/settlementcpa',
				name: 'settlementcpa',
				component: () => import('@/views/Agent/settlementcpa.vue'),
				meta: {
					title: 'CPA结算	',
				},
			},
			{
				path: '/settlementcps',
				name: 'settlementcps',
				component: () => import('@/views/Agent/settlementcps.vue'),
				meta: {
					title: 'CPS结算	',
				},
			},
			{
				path: '/totalcps',
				name: 'totalcps',
				component: () => import('@/views/Agent/TotalCps.vue'),
				meta: {
					title: 'CPS统计	',
				},
			},
			
			{
				path: '/todayactive',
				name: 'todayactive',
				component: () => import('@/views/User/TodayActive.vue'),
				meta: {
					title: '今日活跃用户',
				},
			},
			{
				path: '/alluserList',
				name: 'alluserList',
				component: () => import('@/views/User/AllUserList.vue'),
				meta: {
					title: '用户管理-全部用户',
				},
			},
			
			{
				path: '/userlist',
				name: 'userlist',
				component: () => import('@/views/User/UserList.vue'),
				meta: {
					title: '用户管理-游客',
				},
			},
			{
				path: '/userlist2',
				name: 'userlist2',
				component: () => import('@/views/User/UserList2.vue'),
				meta: {
					title: '用户管理-VIP',
				},
			},
			{
				path: '/userlist3',
				name: 'userlist3',
				component: () => import('@/views/User/UserList3.vue'),
				meta: {
					title: '用户管理-传作者',
				},
			},
			{
				path: '/userlist4',
				name: 'userlist4',
				component: () => import('@/views/User/UserList4.vue'),
				meta: {
					title: '用户管理-官方人员',
				},
			},
			// {
			// 	path: '/userlist5',
			// 	name: 'userlist5',
			// 	component: () => import('@/views/User/UserList5.vue'),
			// 	meta: {
			// 		title: '用户管理-审核创作者',
			// 	},
			// },
			{
				path: '/userpicture',
				name: 'userpicture',
				component: () => import('@/views/User/UserPicture.vue'),
				meta: {
					title: '用户头像',
				},
			},
			{
				path: '/adminset',
				name: 'adminset',
				component: () => import('@/views/Admin/AdminSet.vue'),
				meta: {
					title: '管理员管理',
				},
			},
			{
				path: '/applist',
				name: 'applist',
				component: () => import('@/views/AppCenter/AppList.vue'),
				meta: {
					title: '应用中心',
				},
			},
			{
				path: '/versionset',
				name: 'versionset',
				component: () => import('@/views/Version/VersionSet.vue'),
				meta: {
					title: '安卓版本管理',
				},
			},
			
		],
	},
	{
		path: "/login",
		name: "登录",
		icon: "SwitchButton",
		hidden: false,
		component: () => import("@/views/Login/Login.vue"),
	},


]


const router = createRouter({
	//history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title == undefined ? '抖阴管理系统' : to.meta.title
	// 定义变量表示是否从后端接口中拿到token 拿到token即为true 反之为false
	const isLogin = window.sessionStorage.zijiuserinfo != undefined ? JSON.parse(window.sessionStorage
		.zijiuserinfo).Token : false;
	// 如果当前页面路由路径为/login（登录页面）或者/register（注册页面）
	if (to.path == "/login") {
		next() // 则执行next()方法 跳转到下一个页面（自己设置）
	} else {
		// 如果当前页面不是上面两个页面的路径之一 则执行以下语句
		if (!isLogin) { // 进一步判断有没有获取到token 没有获取到则说明没有经过登陆操作
			
		}
		// 获取到了则跳转下一个页面（自定） 没有获取到则返回登录页面
		isLogin ? next() : next("/login")

	}
})


export default router