<template>
	<router-view></router-view>
</template>

<script setup>
	
	

	
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的
const debounce = (fn, delay) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
</script>

<style>
* {
	padding: 0;
	margin: 0;
}
::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	display: none;
}
</style>
