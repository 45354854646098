import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as echarts from 'echarts'

import {ElMessageBox,ElMessage} from 'element-plus'


import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css


const app = createApp(App)

app.use(vue3videoPlay)
app.config.globalProperties.$imgurl="https://upload.appinfo.live/"
app.use(router).use(ElementPlus, {
	locale: zhCn
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}


//createApp(App).use(router).mount('#app')
app.mount('#app')